<template>
    <component :is="tag" :class="className" v-reveal.once="reveal">
        <span v-if="bg" class="l-hero__bg"></span>
        <div class="l-hero__inner" :class="{ 'no-image': image && image.length === 0 }">
            <div class="l-hero__col -image">
                <slot name="before-image"></slot>
                <asset :cover="display !== 'image-top'" :asset="image" :placeholder="false" class="l-hero__image" />
                <slot name="after-image"></slot>
            </div>
            <div class="l-hero__col -content">
                <slot name="before-content"></slot>
                <div class="t-content">
                    <anim-text
                        :tag="titleTag"
                        :text="title"
                        :reveal="false"
                        ref="title"
                        class="l-hero__title"
                        :class="titleClass"
                        :options="{ inDelay: 0.6 }"
                    />
                    <anim-text :cms="true" :text="intro" :reveal="false" class="l-hero__intro" />
                </div>
                <slot name="after-content"></slot>
            </div>
            <slot name="after-inner"></slot>
        </div>
    </component>
</template>

<script>
import Asset from "objects/Asset";
import Rollup from "objects/Rollup";
import AnimText from "objects/AnimText";

export default {
    name: "AppHero",
    components: {
        Asset,
        Rollup,
        AnimText
    },
    props: {
        bg: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: "header"
        },
        title: {
            type: String,
            default: null
        },
        intro: {
            type: String,
            default: null
        },
        image: {
            type: Object | Array,
            default: null
        },
        display: {
            type: String, // overlap|image-left|image-right|image-top
            default: null
        },
        titleClass: {
            type: String,
            default: "t-t2"
        },
        titleTag: {
            type: String,
            default: "h1"
        }
    },
    data: () => ({
        revealClass: true,
        isRevealed: false
    }),
    computed: {
        className() {
            let classname = "l-hero";

            if (this.display) {
                classname += ` -${this.display}`;
            }

            if (this.revealClass) {
                classname += " -reveal";

                if (this.isRevealed) {
                    classname += " is-revealed";
                }
            }

            return classname;
        }
    },
    methods: {
        reveal() {
            this.isRevealed = true;
            this.$refs.title.show();

            setTimeout(() => {
                // this.revealClass = false
                // this.isRevealed = false
            }, 1200);
        }
    }
};
</script>

<style lang="scss">
.l-hero {
    --image-radius: 30vw;

    &.-overlap {
        .l-hero__inner {
            align-items: flex-end;
            min-height: 34em;
            height: vh(65);

            html.is-mobile-safari & {
                height: 35em;

                @media #{md("sm")} {
                    min-height: 40em;
                    height: 40em;
                }
            }

            @media #{md("sm")} {
                min-height: 40em;
                height: vh(90);
            }
        }

        .l-hero__col {
            &.-image {
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - var(--grid-start-x));
                height: 100%;
            }

            &.-content:nth-child(2) {
                color: $color-light;
            }
        }

        .l-hero__image {
            border-bottom-right-radius: var(--image-radius);
        }
    }

    &.-image-left {
        .l-hero__image {
            border-bottom-right-radius: var(--image-radius);
        }
    }

    &.-image-right {
        .l-hero__image {
            border-bottom-left-radius: var(--image-radius);
        }
    }

    &.-image-top {
        --image-radius: 50vw;

        .l-hero__inner {
            justify-content: center;
            text-align: center;

            &.no-image {
                padding-top: 6rem;
            }
        }

        .l-hero__col {
            &.-image {
                width: 100%;
            }
        }

        .l-hero__image {
            border-bottom-right-radius: calc(var(--image-radius) / 2);
            border-bottom-left-radius: calc(var(--image-radius) / 2);
        }
    }

    @media #{md("xs", "max")} {
        &.-image-top {
            .o-asset__img {
                height: vh(50);
                width: 100%;
                object-fit: cover;
                object-position: 50% 50%;

                html.is-mobile-safari & {
                    height: 24em;
                }
            }
        }

        &.-overlap {
            .l-hero__col.-content {
                width: calc(100% - var(--grid-start-x));
            }
        }
    }

    @media #{md("md", "max")} {
        &.-overlap,
        &.-image-left,
        &.-image-right {
            .l-hero__col.-image {
                padding-top: vh(50);

                html.is-mobile-safari & {
                    padding-top: 30em;
                }
            }
        }

        &.-image-left,
        &.-image-right {
            .l-hero__col.-content:nth-child(2) {
                padding-top: vh(5);

                html.is-mobile-safari & {
                    padding-top: 3rem;
                }
            }
        }

        &.-image-right {
            .l-hero__col.-image {
                margin-left: auto;
            }
        }

        &.-image-top {
            .l-hero__col.-image {
                width: calc(100% - 2 * var(--grid-start-x));
            }
        }
    }

    @media #{md("md")} {
        &.-overlap {
            .l-hero__inner {
                padding: vh(10) var(--grid-start-x);

                html.is-mobile-safari & {
                    padding: 4.5rem var(--grid-start-x);
                }
            }
        }

        &.-image-left,
        &.-image-right {
            .l-hero__inner {
                align-items: stretch;
                flex-wrap: nowrap;
            }

            .l-hero__col {
                &.-image {
                    width: 50%;

                    .o-asset__img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: 50% 50%;
                    }
                }

                &.-content {
                    padding-top: vh(25);
                    padding-bottom: vh(25);

                    html.is-mobile-safari & {
                        padding-top: 12em;
                        padding-bottom: 12em;
                    }
                }
            }
        }

        &.-image-left {
            .l-hero__col {
                &.-content {
                    padding-right: var(--grid-start-x);
                    padding-left: calc(1 / 12 * 100%);
                }
            }
        }

        &.-image-right {
            .l-hero__inner {
                flex-direction: row-reverse;
            }

            .l-hero__col {
                &.-content {
                    padding-right: calc(1 / 12 * 100%);
                    padding-left: var(--grid-start-x);
                }
            }
        }

        &.-image-top {
            .l-hero__inner {
                padding-right: var(--grid-start-x);
                padding-left: var(--grid-start-x);
            }

            .l-hero__col.-content:nth-child(2) {
                padding-top: var(--grid-start-x);
                padding-bottom: var(--grid-start-x);
            }
        }
    }

    @media #{md("lg")} {
        &.-image-top {
            .l-hero__col.-content:nth-child(2) {
                padding-top: vh(10);
                padding-bottom: vh(10);
            }
        }
    }

    &.-reveal {
        .l-hero__image {
            // Safari radius fix
            -webkit-mask-image: -webkit-radial-gradient(white, black);

            &:before {
                @include pseudo-el($bg: $color-secondary);
                position: absolute;
                top: 0;
                left: 0;
            }

            .o-asset__img {
                opacity: 0;
                transform: scale(1.05);
                transform-origin: 50% 100%;
            }
        }

        &.is-revealed {
            .l-hero__image {
                .o-asset__img {
                    opacity: 1;
                    transform: scale(1);
                    transition: all 0.5s $out-quad 0.5s;
                    transition: transform 0.8s $out-quad, opacity 0.4s ease-out;
                }
            }
        }
    }
}

.l-hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: calc(100% + 1px); // Fix pixel bug
    background-color: $color-primary;

    & + .l-hero__inner {
        color: $color-light;
    }
}

.l-hero__inner {
    display: flex;
    flex-wrap: wrap;
}

.l-hero__col {
    @media #{md("md", "max")} {
        &.-content {
            padding: var(--grid-start-x);
        }

        &.-image {
            width: calc(100% - var(--grid-start-x));
        }
    }

    @media #{md("xs")} {
        &.-content {
            width: calc(5 / 6 * 100%);
        }
    }

    @media #{md("sm")} {
        &.-content {
            width: calc(3 / 4 * 100%);
        }
    }

    @media #{md("md")} {
        &.-content {
            width: calc(1 / 2 * 100%);
        }
    }
}

.l-hero__image {
    overflow: hidden;
}
</style>
