import { fetchApi } from "src/graphql/config";
import queries from "src/graphql/queries";
import { linkResolver } from "src/router";

/*
 * Global
 */

const initialData = window.__initialData__;

// State
const state = {
    nav: [],
    forms: null,
    banners: [],
    regions: [],
    references: [],
    partnerCategories: [],
    settings: {},
    currentPage: {},
    currentPageName: null,
    previousRoute: null
};

// Getters
const getters = {
    getBannerById: state => id => state.banners.find(b => b.id == id),
    getBannersByIds: state => ids => state.banners.filter(b => ids.includes(b.id)),
    getRegionById: state => id => state.regions.find(r => r.id == id),
    getRegionsByIds: state => ids => state.regions.filter(r => ids.includes(r.id)),
    hasCurrentPage: state => !(Object.keys(state.currentPage).length === 0 && state.currentPage.constructor === Object),
    getAward: state => {
        const _settings = state.settings;

        if (_settings.displayAward === undefined) return;

        return {
            display: _settings.displayAward,
            logo: _settings.awardLogo.length ? _settings.awardLogo[0] : null,
            content: _settings.awardText
        };
    },
    hasAward: state => state.settings.displayAward
};

// Actions
const actions = {
    // Initial load for navigation, globals, etc...
    initLoad(store) {
        store.commit("setBanners", initialData.banners);

        return Promise.all([
            store.dispatch("loadGeneral"),
            store
                .dispatch("stores/preloadAll", null, { root: true })
                .then(() => store.dispatch("jobs/preloadAll", null, { root: true })),
            store.dispatch("pages/loadSingle", "articles", { root: true })
        ]).catch(e => {
            console.error(e.message); // eslint-disable-line
        });
    },
    loadGeneral(store) {
        fetchApi(queries.general)
            .then(data => {
                // Format nav and add route path to each nav item
                const nav = data.nav.navNodes;

                nav.forEach(item => {
                    item.entry = item.entry[0];
                    item.path = linkResolver(item.entry.sectionHandle);
                });

                store.commit("setNav", nav);
                store.commit("setForms", data.forms);
                store.commit("setRegions", data.regions);
                store.commit("setReferences", data.formFields);
                store.commit("setPartnerCategories", data.partnerCategories);
                store.commit("setSettings", data.settings);
            })
            .catch(e => {
                console.error(e.message); // eslint-disable-line
            });
    },
    loadContent(store, to) {
        return new Promise((resolve, reject) => {
            // Timer in seconds
            const timer = new Date();

            // Start loader
            store.dispatch("loader/startLoad", null, { root: true });

            // Load page content
            let fetchContent;
            const handle = to.meta.section;
            const singleEntry = to.meta.single;

            // Empty promise if nothing to load (static page)
            if (typeof handle === "undefined") {
                fetchContent = new Promise(r => r({ static: true }));

                // For single entries, load page with handle
            } else if (singleEntry) {
                fetchContent = store.dispatch("pages/loadSingle", handle, { root: true });

                // For articles
            } else if (to.name == "article") {
                const slug = to.params.slug;
                fetchContent = store.dispatch("articles/loadSingle", slug, { root: true });

                // For channel/structure, load page with handle and slug
            } else {
                const slug = to.params.slug;
                fetchContent = store.dispatch("pages/loadEntry", { handle, slug }, { root: true });
            }

            fetchContent
                .then(page => {
                    // Duration in ms of the fetchContent + 300ms for the loader cover delay (see loader component) + 10ms for latency
                    // let delay = store.rootState.loader.isVisible ? (timer - new Date) + 800 : 0
                    let delay = timer - new Date() + 800;
                    delay = delay < 0 ? 0 : delay;

                    setTimeout(() => {
                        store.commit("setPage", {});
                        store.commit("setPageName", to.name);
                        resolve(page);

                        // Timeout to let template to render data
                        setTimeout(() => {
                            // Set page after router page has updated to avoid js errors
                            store.commit("setPage", page);
                            store.dispatch("loader/endLoad", null, { root: true });
                        }, 10);
                    }, delay);
                })
                .catch(e => {
                    reject(e);
                    store.dispatch("loader/endLoad", null, { root: true });
                });
        });
    },
    updatePreviousRoute(store, route) {
        store.commit("setPreviousRoute", route);
    }
};

// Mutations
const mutations = {
    setNav(state, nav) {
        state.nav = nav;
    },
    setBanners(state, banners) {
        state.banners = banners;
    },
    setRegions(state, regions) {
        state.regions = regions;
    },
    setReferences(state, setReferences) {
        state.references = setReferences.references;
    },
    setPartnerCategories(state, partnerCategories) {
        state.partnerCategories = partnerCategories;
    },
    setForms(state, forms) {
        state.forms = forms;
    },
    setSettings(state, settings) {
        state.settings = settings;
    },
    setPage(state, page) {
        state.currentPage = page;
    },
    setPageName(state, name) {
        state.currentPageName = name;
    },
    setPreviousRoute(state, route) {
        state.previousRoute = route;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
