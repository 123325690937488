<template>
    <div class="m-job">
        <anim-text text="Candidature spontanée" class="m-job__title | t-t2" />
        <app-form
            v-if="forms"
            formName="Recrutement"
            formTemplate="job"
            :toEmail="forms.jobRecipient"
            subject="Réception d’une nouvelle candidature spontanée"
            :inputs="inputs"
            submitLabel="Postuler"
            class="m-job__form"
        >
            <template #success>
                <div class="t-cms" v-html="forms.jobConfirmation" />
            </template>
        </app-form>
    </div>
</template>

<script>
import AnimText from "objects/AnimText";
import AppForm from "components/AppForm";

import { mapState } from "vuex";

export default {
    name: "SimpleJobModal",
    components: {
        AnimText,
        AppForm
    },
    data: () => ({
        inputs: [
            {
                title: "Préférences"
            },
            {
                label: "Magasin",
                name: "store",
                options: [],
                as: "title:title",
                required: false,
                half: true
            },
            {
                label: "Quart de travail",
                name: "shift",
                options: [
                    {
                        title: "Temps plein"
                    },
                    {
                        title: "Temps partiel"
                    },
                    {
                        title: "Saisonnier"
                    },
                    {
                        title: "Aucune préférence"
                    }
                ],
                as: "title:title",
                required: true,
                half: true
            },
            {
                title: "Informations"
            },
            {
                label: "Prénom",
                name: "firstName",
                type: "text",
                required: true,
                autocomplete: "given-name",
                half: true
            },
            {
                label: "Nom",
                name: "lastName",
                type: "text",
                required: true,
                autocomplete: "family-name",
                half: true
            },
            {
                label: "Courriel",
                name: "fromEmail",
                type: "email",
                required: true,
                autocomplete: "email",
                half: true
            },
            {
                label: "Téléphone",
                name: "phone",
                type: "tel",
                required: true,
                autocomplete: "tel",
                half: true
            },
            {
                label: "Où avez-vous vu entendu parler de nous",
                name: "reference",
                options: [],
                as: "title:title",
                required: false,
                half: false
            },
            {
                label: "Qui êtes-vous ?",
                name: "body",
                type: "textarea"
            },
            {
                label: "Curriculum vitae (PDF, JPG, PNG sont acceptés)",
                type: "file",
                name: "attachment[]"
            },
            {
                label: "Autre document (PDF, JPG, PNG sont acceptés)",
                type: "file",
                name: "attachment[]"
            }
        ]
    }),
    created() {
        const idx = this.inputs.findIndex(f => f.name == "store");
        this.$set(this.inputs[idx], "options", this.stores);

        const idx2 = this.inputs.findIndex(f => f.name == "reference");
        this.$set(this.inputs[idx2], "options", this.references);
    },
    computed: {
        ...mapState({
            forms: state => state.global.forms,
            references: state => state.global.references,
            stores: state => state.stores.elements
        })
    }
};
</script>

<style lang="scss">
.m-job {
}

.m-job__title {
    text-align: center;
}

.m-job__form {
    margin-top: vh(10);

    @media #{md("xs")} {
        width: calc(5 / 6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(3 / 4 * 100%);
    }

    @media #{md("md")} {
        width: calc(2 / 3 * 100%);
    }
}
</style>
